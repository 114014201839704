@import './styles.scss';

html {
	--ion-font-family: 'メイリオ', 'Hiragino Kaku Gothic Pro', 'Meiryo', 'ヒラギノ角ゴ Pro W3', 'MS PGothic', 'MS UI Gothic', Helvetica, Arial, sans-serif;
}

//////////////////////////////////////////////////////////////////////
// アプリスタイル
// 全体
.div-app {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

// ヘッダーメニュー
.div-app-header {
	background-color: #f8f8f8;
	--ion-background-color: #f8f8f8;
	border-bottom: 1px solid #e7e7e7;
}

// グローバル（サイド）メニュー部分
.div-app-main-side-globalmenu {
	height: 100%;
	width: 100px; // 幅は仮置
	// ボーダーをHTMLに直接記載している
}

// サイドグローバルメニューとフラグメントを含んだブロック
.div-app-main {
	height: 100%;
	// width: 100%;

	// グローバルサイドメニューとフラグメント部分を左右に配置
	// グローバルサイドメニューを固定にして、フラグメント部分を可変幅にしてる
	display: flex;
	overflow: auto;
	flex-direction: column;
}

// コンテンツの高さ
// header と footerの高さを抜く
.div-app-content-root {
	height: 100%;
}

// 各メインフラグメント
.div-app-main-display {
	// flex: auto;
	height: 100%;
	width: 100%;
	display: flex;
    justify-content: center;
}

.div-app-footer {
	height: 48px;
	.footer-spacer {
		flex: 1 1 auto;
	}
	@media (max-width: 599px){

		.link-text {
			display: inline-block;
			width: 50%;
			text-align: left;
			padding: 0 0;
		}
	}
}
//////////////////////////////////////////////////////////////////////
// フラグメント用スタイル
// フラグメントの中身部分
.div-fragment-main {
	width: 100%;
	height: 100%;
	// display: flex;
	justify-content: center;
}

.div-fragment-main-panel {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
}

.div-fragment-main-panel-container {
	width: 100%;
	height: calc(100% - 56px);
	padding-bottom: 20px;
	overflow: auto;
	position: relative;
}

@media (max-width: 599px) {
.div-fragment-main-panel-container {

	width: 100%;
	height: calc(100% - 56px);
	padding-bottom: 20px;
	overflow: auto;

}
}

// フラグメント領域のアクションエリア
.div-fragment-main-panel-action {
	background-color: #fff;
	margin: 10px;
	padding: 5px 23px 5px 23px;
	border-bottom: 1px solid #eee;
	border-top: 1px solid #eee;
	button {
		height: 30px;
		line-height: 3px;
		margin-left: 5px;
	}
	.mat-button-wrapper {
		font-size: 8px;
	}
}

//////////////////////////////////////////////////////////////////////
// パネル用スタイル
// パネルのアクションエリア(サーベイ管理のみ)
.div-panel-main-action {
	background-color: #fff;
	height: 40px;
	margin-top: -30px;
	margin-bottom: 30px;
	padding: 5px 23px 5px 23px;
	border-bottom: 1px solid #eee;
	border-top: 1px solid #eee;
}

// パネルのアクションエリア(サーベイ管理のみ)
.div-panel-main-action div {
	position: relative;
	float: left;
}

// パネルのアクションエリアのボタン(サーベイ管理)
.div-panel-main-action-left {
	width: 80%;
	button {
		height: 30px;
		line-height: 3px;
	}
	.mat-button-wrapper {
		font-size: 8px;
	}
}

.div-panel-main-action-right {
	width: 20%;
	button {
		height: 30px;
		line-height: 3px;
	}
	.mat-button-wrapper {
		font-size: 8px;
	}
}

// 登録画面の日付ピッカー用
input.schedule-input-area {
	width: 200px;
	height: 25px;
}

//////////////////////////////////////////////////////////////////////
// パネルの詳細用スタイル
// パネルのアクションエリア(サーベイ管理のみ)
.div-panel-detail-action {
	background-color: #fff;
	height: 80px;
	margin-top: 10px;
	margin-bottom: 30px;
	padding: 20px 23px 20px 23px;
	border-bottom: 1px solid #eee;
	border-top: 1px solid #eee;
}

// パネルのアクションエリア(サーベイ管理のみ)
.div-panel-detail-action div {
	position: relative;
	float: left;
}

//////////////////////////////////////////////////////////////////////
// パーツ用 共通スタイル

///////////////////////////////////
// スライドアニメーションするブロックの親用 親がこれでないとだめ
.slide-animation-parent {
	position: relative;
	width: 100%;
}
// スライドアニメーションするブロック用
.slide-animation {
	position: absolute; // relativeの上に、absoluteのブロックが来ると重なる。
	top: 0px;
	z-index: 500; //Mat系のラベルで100のヤツがいるので大きくしておく。でないとパネルが上に乗らない
	width: 100%;
	height: 100%;

	display: table; // これをいれないと、中身のテーブルとかがはみ出るが、.mat-cardがdisplay:blockに、どこからか上書きされてて、これ変えられれば、これいらないとおもわれる

	// 背景をいれないと下が透けてしまう
	background-color: #fff;

	// // 検証用
	// border: 1px solid black;
}

// テーブル
.th.mat-header-cell {
	background-color: #fff;
	color: #ddd;
	font-size: 14px;
	text-align: center;
}

///////////////////////////////////
// 汎用ボタン
// ボタン
.button-right {
	float: right;
}

////////////////////////////////////////////////////////
// ポップアップ
.mat-dialog-title {
	background-color: #f8f8f8;
	height: 40px;
}
.dialog-header {
	display: flex;
	background: rgba(255,255,255,0);
	color: black;
}
.example-spacer {
	flex: 1 1 auto;
}

.cdk-overlay-container {
	z-index: 30000 !important;
}
